<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="55"
      >
        <v-toolbar-title>
          <b class="mr-3"
            ><v-btn icon @click="$router.go(-1)"
              ><v-icon>arrow_back</v-icon></v-btn
            >
            Shipment Overview</b
          >
        </v-toolbar-title>
      </v-toolbar>

      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }"
      >
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row align="center" style="font-size: 12px" class="mb-0 pb-0">
            <v-col cols="12" sm="3" md="2" class="my-0 py-0">
              <v-card class="text-center" flat>
                <v-col cols="12" class="text-center my-0 py-0">
                  <v-icon size="40px">outbound</v-icon>
                </v-col>
                {{ $route.query.shipper }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="3" md="2" class="text-center mx-0 px-0">
              <v-card flat class="text-center mx-0 px-0">
                <v-col cols="12" class="text-center my-0 py-0">
                  <v-icon size="40px">call_received</v-icon>
                </v-col>
                {{ $route.query.consignee }}
              </v-card>
            </v-col>
            <v-col cols="12" sm="3" md="2" class="text-center mx-0 px-0 my-0 py-0">
              <v-card class="text-center" flat>
                <v-list-item class="my-0 py-0 text-left" style="height: 30px">
                  <v-list-item-action>
                    <v-icon size="30px">directions_boat</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $route.query.vessel }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Voyage: {{ $route.query.voyage }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="
                      vesselTracking &&
                      vesselTracking.routeHistory &&
                      vesselTracking.routeHistory.length > 0
                    "
                  >
                    <v-btn
                      icon
                      color="primary"
                      @click="showVesselTracking = true"
                    >
                      <v-icon>gps_not_fixed</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" sm="3" md="2" class="text-center mx-0 px-0">
                            <v-card class="text-center" flat>
                                <v-list-item class="my-0 py-0 text-left">
                                    <v-list-item-action>
                                        <v-icon size="30px">schedule</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <span style="font-size: 14px; color: var(--v-primaryText-base)">ETD: {{
                                            $route.query.etd }}</span>
                                        <br />
                                        <span style="font-size: 14px; color: var(--v-primaryText-base)">ETA: {{
                                            $route.query.eta }}</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col> -->
            <v-col cols="12" sm="3" md="2" class="my-0 py-0">
              <v-card class="text-center my-0 py-0" flat>
                <v-card-text :key="locationKey">
                  <div class="text-center mb-0 pb-0">
                    <h4 class="my-0 py-0" style="bottom: -20px">
                      <!-- <v-icon :color="getStatusColor($route.query.status)" class="mr-1">{{
                                                getStatusIcon($route.query.status) }}</v-icon>  -->
                      {{ $route.query.status }}
                      <span
                        v-if="
                          progress <
                          calculateDays($route.query.etd, $route.query.eta)
                        "
                        style="font-weight: light; font-size: 12px"
                      >
                        <br />
                        {{
                          calculateDays($route.query.etd, $route.query.eta) -
                          progress
                        }}
                        days remaining
                      </span>
                    </h4>
                    <!-- {{ $route.query.pol }} -->
                  </div>
                  <v-slider
                    class="my-0 py-0"
                    :value="progress"
                    readonly
                    :color="
                      progress == 0
                        ? 'grey'
                        : progress <
                          calculateDays($route.query.etd, $route.query.eta)
                        ? 'blue'
                        : 'green'
                    "
                    :max="calculateDays($route.query.etd, $route.query.eta)"
                    :thumb-size="35"
                    thumb-color="grey"
                    hide-details
                  >
                    <template v-slot:prepend>
                      <v-row justify="center" align="center">
                        <v-avatar size="32" class="mx-1">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${$route.query.pol
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        <span class="mt-1">{{ $route.query.pol }}</span>
                      </v-row>
                    </template>

                    <template v-slot:append>
                      <v-row justify="center" align="center">
                        <v-avatar size="32" class="mx-1">
                          <v-img
                            contain
                            :src="`https://cdn.loglive.io/flags/4x3/${$route.query.pod
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        <span class="mt-1">{{ $route.query.pod }}</span>
                      </v-row>
                    </template>
                  </v-slider>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="3" md="2" class="my-0 py-0">
              <v-list dense style="font-size: 11px" class="text-left">
                    <v-list-item style="height: 25px">
                      <v-list-item-content>
                        <v-list-item-title>
                          Containers
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small>
                          {{ $route.query.noContainers }}

                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 25px">
                      <v-list-item-content>
                        <v-list-item-title>
                          Pallets
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small>
                          {{ $route.query.noPallets }}

                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 25px">
                      <v-list-item-content>
                        <v-list-item-title>
                          Units
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small>
                          {{ $route.query.noCartons }}

                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
            </v-col>
          </v-row>
          <v-card
            flat
            class="ma-0 pa-0"
            style="min-height: 70vh; max-height: 75vh; overflow-y: auto"
          >
            <v-card-text class="my-0 py-0">
              <div v-if="!loading">
                <v-row>
                  <v-col cols="12" sm="3" md="2" class="mx-0 px-0">
                    <v-card flat>
                      <v-tabs v-model="tab" grow dark icons-and-text>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#containers">
                          Containers
                          <v-icon small>widgets</v-icon>
                        </v-tab>
                        <v-tab href="#shipments">
                          Shipments
                          <v-icon small>directions_boat</v-icon>
                        </v-tab>
                      </v-tabs>
                      <v-card-text class="mx-0 px-0">
                        <v-card flat v-if="tab == 'shipments'">
                          <v-card-text class="ma-0 pa-0">
                            <!-- <v-subheader style="font-size: 16px">Shipments</v-subheader>
                                                        <v-divider></v-divider> -->
                            <v-list dense>
                              <v-list-item
                                v-for="shipment in shipments"
                                @click="selectShipment(shipment)"
                                :key="shipment.id"
                                :style="{
                                  'border-left':
                                    selectedShipment &&
                                    selectedShipment.id == shipment.id
                                      ? 'solid 4px var(--v-primary-base)'
                                      : 'solid 4px transparent',
                                  'border-top': '0.5px solid grey',
                                }"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ shipment.shipmentFile.fileNumber }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    <v-chip outlined small style="border: none">
                                      - Containers
                                      <v-avatar
                                        right
                                        size="26"
                                        color="blue-grey"
                                        >{{
                                          shipment.containers.length
                                        }}</v-avatar
                                      >
                                    </v-chip>
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    v-if="
                                      shipment.documentSummary.complete > 0 ||
                                      shipment.documentSummary.incomplete > 0
                                    "
                                  >
                                    <v-chip outlined small style="border: none">
                                      - Documents
                                      <v-chip
                                        right
                                        class="ml-1"
                                        x-small
                                        :color="
                                          shipment.documentSummary.complete ==
                                          shipment.documentSummary.total
                                            ? 'green'
                                            : 'blue'
                                        "
                                      >
                                        {{
                                          shipment.documentSummary.complete
                                        }}
                                        / {{ shipment.documentSummary.total }}
                                      </v-chip>
                                    </v-chip>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                        <v-card flat v-if="tab == 'containers'">
                          <v-card-text class="ma-0 pa-0">
                            <!-- <v-subheader style="font-size: 16px">Containers</v-subheader>
                                                        <v-divider></v-divider> -->
                            <v-list :key="selectKey" dense class="mt-0 pt-0">
                              <v-list-item
                                v-for="(container, index) in containers"
                                :key="index"
                                @click="selectContainer(container)"
                                :style="{
                                  'border-left':
                                    selectedContainer &&
                                    selectedContainer.id == container.id
                                      ? 'solid 4px var(--v-primary-base)'
                                      : 'solid 4px transparent',
                                  'border-top': '0.5px solid grey',
                                }"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ container.containerNo }}
                                    <span v-if="container.ctoNo">
                                      <div
                                        v-if="
                                          container.ctoNo &&
                                          container.containerNo
                                        "
                                      >
                                        -
                                      </div>
                                      {{ container.ctoNo }}</span
                                    >
                                  </v-list-item-title>
                                  <v-list-item-subtitle
                                    v-if="container.customerContainerRef"
                                  >
                                    Ref: {{ container.customerContainerRef }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    v-if="container.noPallets"
                                  >
                                    {{ container.noPallets }} Pallets
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle
                                    v-if="
                                      container.devices &&
                                      container.devices.length > 0
                                    "
                                  >
                                    <v-icon small>thermostat</v-icon>
                                    {{ container.devices.length }} Device(s)
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" sm="9" md="10" class="mx-0 px-0">
                    <v-card flat v-if="selectedShipment">
                      <!-- <v-divider></v-divider> -->
                      <v-toolbar flat dense>
                        <v-toolbar-title>
                          Shipment: {{ selectedShipment.shipmentFile.fileNumber }}
                        </v-toolbar-title>
                        <!-- <v-spacer></v-spacer> -->
                        <v-btn icon @click="viewShipment(selectedShipment)">
                          <v-icon>launch</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text class="my-0 py-0">
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-card style="border-radius: 20px; background: var(--v-toolbar-lighten1) !important">
                                <v-card-title style="font-size: 16px">
                                  <v-icon color="secondary" class="mr-2">article</v-icon>
                                  Documents </v-card-title>
                                <v-card-text class="my-0 py-0">
                                  <v-list dense class="my-0 py-0">
                                    <v-list-item
                                      v-for="document in selectedShipment.shipmentDocumentStatuses"
                                      :key="document.id"
                                      style="height: 45px"
                                    >
                                      <v-list-item-action>
                                        <v-tooltip top>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-icon
                                              v-bind="attrs"
                                              v-on="on"
                                              :color="
                                                getDocumentStatusColor(document.status)
                                              "
                                              >{{
                                                getDocumentStatusIcon(document.status)
                                              }}</v-icon
                                            >
                                          </template>
                                          <span style="font-size: 12px">{{
                                            document.status
                                          }}</span>
                                        </v-tooltip>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ document.locationDocument.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ document.status }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-chip>{{ document.count }}</v-chip>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="12" sm="8">
                              <v-card style="border-radius: 20px; background: var(--v-toolbar-lighten1) !important" :loading="loadingShipment">
                                <v-card-title style="font-size: 16px">
                                  <v-icon color="secondary" class="mr-2">category</v-icon>
                                  Products </v-card-title>
                                <v-card-text style="min-height: 300px">
                                  <ProductBreakdownTable
                            :headers="productSummaryHeaders"
                            :items="shipmentSummaryItems"
                            :tableKey="productKey"
                            :loading="loadingShipment"
                            :height="'100%'"
                          />
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                        <div class="my-2">
                          <!--  -->
                        </div>
                      </v-card-text>
                    </v-card>
                    <v-card flat v-else-if="selectedContainer">
                      <v-card-title>
                        <v-icon class="mr-2" color="primary">widgets</v-icon>
                        Container Overview: {{ selectedContainer.containerNo }}
                      </v-card-title>
                      <v-progress-linear
                        class="mt-1"
                        v-if="loadingContainer"
                        color="primary"
                        indeterminate
                      ></v-progress-linear>
                      <v-card flat v-if="item && item.id">
                        <v-card-text class="mt-0 pt-0">
                          <v-row>
                            <v-col cols="12" sm="3" md="2">
                              <v-card
                                flat
                                style="
                                  background-color: var(
                                    --v-component-base
                                  ) !important;
                                "
                              >
                                <v-card-text>
                                  <v-list dense subheader>
                                    <v-subheader style="font-size: 16px"
                                      >Details</v-subheader
                                    >
                                    <v-divider></v-divider>
                                    <v-list-item v-if="item.containerNo">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >tag</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.containerNo }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle
                                          v-if="item.shipmentFile"
                                        >
                                          {{
                                            !item.shipmentFile.breakBulkShipment
                                              ? "Container No."
                                              : "Deck No."
                                          }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="item.ctoNo">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >description</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.ctoNo }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          CTO Number
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="item.sealNo">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >label</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.sealNo }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          Seal Number
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >scale</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ containerGrossWeight(item) }} KGS
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          Gross Weight
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item v-if="item.containerNo">
                                      <!-- <v-list-item-action>
                                        <v-icon color="secondary" small
                                          >scale</v-icon
                                        >
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ containerNettWeight(item) }} KGS
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          Nett Weight
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider> </v-divider>
                                    <v-subheader style="font-size: 16px">
                                      Commodities</v-subheader
                                    >
                                    <v-divider></v-divider>
                                    <v-list-item
                                      v-for="product in containerProducts"
                                      :key="product.productId"
                                    >
                                      <!-- <v-list-item-action>
                                        <v-chip>{{ index + 1 }}</v-chip>
                                      </v-list-item-action> -->
                                      <v-list-item-content>
                                        <v-list-item-title v-if="product.product">
                                          {{ product.product.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ product.noPallets }} Pallets
                                        </v-list-item-subtitle>
                                        <div v-if="binsAndCartons">
                                          <v-list-item-subtitle
                                            style="font-size: 12px"
                                          >
                                            <v-icon small class="mr-1"
                                              >widgets</v-icon
                                            >
                                            {{
                                              calculateProductBins(
                                                product.productId
                                              )
                                            }}
                                            BINS
                                          </v-list-item-subtitle>
                                          <v-list-item-subtitle
                                            style="font-size: 12px"
                                          >
                                            <v-icon small class="mr-1"
                                              >widgets</v-icon
                                            >
                                            {{
                                              calculateProductCartons(
                                                product.productId
                                              )
                                            }}
                                            CARTONS
                                          </v-list-item-subtitle>
                                        </div>
                                        <div v-else>
                                          <v-list-item-subtitle
                                            style="font-size: 12px"
                                          >
                                            <v-icon small class="mr-1"
                                              >widgets</v-icon
                                            >
                                            {{
                                              getPackType() == "BIN"
                                                ? calculateProductBins(
                                                    product.productId
                                                  )
                                                : calculateProductCartons(
                                                    product.productId
                                                  )
                                            }}
                                            {{ getPackType() + "S" }}
                                          </v-list-item-subtitle>
                                        </div>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider> </v-divider>
                                    <v-subheader style="font-size: 16px">
                                      Device(s)
                                    </v-subheader>
                                    <v-divider></v-divider>
                                    <v-progress-linear
                                      color="primary"
                                      indeterminate
                                      v-if="loadingSensor"
                                    ></v-progress-linear>
                                    <v-list dense :key="telemetryKey">
                                      <v-list-item
                                        v-for="device in selectedContainer.telemetry"
                                        :key="device.id"
                                      >
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ device.serial }}
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                            <v-avatar
                                              size="16"
                                              class="mr-1"
                                              v-if="
                                                device.provider == 'SensiTech'
                                              "
                                            >
                                              <img
                                                contain
                                                src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwEKCgkBDRYDDQEBARsIFRAWIB0iIiAdExMkKDQsJCYxJx8fLTEtMTU3Ojo6Iys/RD84QzQ5OisBCgoKDQwBGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Kzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIABAAEAMBIgACEQEDEQH/xAAWAAADAAAAAAAAAAAAAAAAAAAEBQb/xAAnEAAABQMCBQUAAAAAAAAAAAABAgMEBgUHEQBBFCEyUWESFRYjMf/EABQBAQAAAAAAAAAAAAAAAAAAAAT/xAAYEQACAwAAAAAAAAAAAAAAAAABAgAGEf/aAAwDAQACEQMRAD8Av6zWFDleuBnSyNPsI4CFhCbQSUEDuFsh1Kj0FARAOXnOmkdVVXSEVIy8bmh5uCPCbtXSGoAbsJFd/wB0J7Q8IvUGKUn9Le+6/wA3JcyTwIrwuRx60lScsh28b8tGx+hlopnhizApguscHgW3t7AeESSwGPrTyOM76Q5QVnBDIGNg0z//2Q==
                    "
                                              />
                                            </v-avatar>
                                            {{ device.provider }}
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-list-item
                                        v-if="
                                          !loadingSensor &&
                                          item.telemetry &&
                                          item.telemetry.length == 0
                                        "
                                      >
                                        <v-list-item-content
                                          class="text-center"
                                        >
                                          <span
                                            style="color: grey; font-size: 12px"
                                            >No devices found</span
                                          >
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                              <v-tabs
                                v-model="containerTab"
                                :key="telemetryKey"
                              >
                                <v-tabs-slider color="primary"></v-tabs-slider>
                                <v-tab href="#summary"> Summary </v-tab>
                                <v-tab
                                  href="#telemetry"
                                  v-if="displayTelemetry"
                                >
                                  Telemetry
                                </v-tab>
                                <v-tab href="#contents"> Contents </v-tab>
                              </v-tabs>
                              <v-tabs-items
                                v-model="containerTab"
                                style="background-color: transparent"
                              >
                                <v-tab-item
                                  value="summary"
                                  style="background-color: transparent"
                                >
                                  <v-card
                                    flat
                                    style="border-radius: 0px"
                                    class="mt-0 pt-0"
                                  >
                                    <v-card-text>
                                      <v-row class="mt-3">
                                        <v-col cols="12">
                                          <v-card
                                            flat
                                            style="
                                              background: var(
                                                --v-component-base
                                              ) !important;
                                            "
                                          >
                                            <v-subheader style="font-size: 16px"
                                              ><v-icon
                                                color="secondary"
                                                class="mr-2"
                                                >category</v-icon
                                              >
                                              Product Breakdown</v-subheader
                                            >
                                            <v-divider></v-divider>
                                            <v-card-text>
                                              <ProductBreakdownTable
                                                :headers="productSummaryHeaders"
                                                :items="
                                                  selectedContainer.productSummary
                                                "
                                                :tableKey="productKey"
                                                :loading="
                                                  loadingContainerBreakdown
                                                "
                                                :height="'100%'"
                                              />
                                            </v-card-text>
                                          </v-card>
                                          <v-card
                                            flat
                                            style="
                                              background: var(
                                                --v-component-base
                                              ) !important;
                                              border-radius: 20px;
                                            "
                                            class="mt-3"
                                          >
                                            <v-card-title>
                                              <v-icon
                                                color="secondary"
                                                class="mr-2"
                                                >category</v-icon
                                              >
                                              Container Events
                                            </v-card-title>
                                            <v-card-text>
                                              <v-row>
                                                <v-col cols="12" sm="12">
                                                  <ul class="events">
                                                    <li
                                                      v-for="eventData in item.containerMilestones"
                                                      :key="eventData.id"
                                                      style="
                                                        list-style-type: none;
                                                      "
                                                      class="my-3"
                                                    >
                                                      <v-avatar
                                                        class="mb-1 mr-2"
                                                        size="10"
                                                        :color="
                                                          eventData.isActual
                                                            ? 'success'
                                                            : 'secondary'
                                                        "
                                                      ></v-avatar>
                                                      <strong
                                                        style="font-size: 14px"
                                                        >{{
                                                          formatDateTime(
                                                            eventData.date
                                                          )
                                                        }}</strong
                                                      >
                                                      <v-chip
                                                        outlined
                                                        style="border: none"
                                                        small
                                                        v-if="
                                                          eventData.location
                                                        "
                                                      >
                                                        <v-avatar
                                                          size="20"
                                                          left
                                                        >
                                                          <v-img
                                                            contain
                                                            :src="`https://cdn.loglive.io/flags/4x3/${eventData.location
                                                              .substring(0, 2)
                                                              .toLowerCase()}.svg`"
                                                          ></v-img>
                                                        </v-avatar>
                                                        {{ eventData.location }}
                                                      </v-chip>
                                                      <span
                                                        class="ml-2"
                                                        style="font-size: 14px"
                                                        >{{
                                                          eventData.description
                                                        }}</span
                                                      >
                                                      <span
                                                        v-if="
                                                          eventData.vesselName
                                                        "
                                                        class="ml-2"
                                                        >-
                                                        {{
                                                          eventData.vesselName
                                                        }}
                                                        {{
                                                          eventData.voyageNo
                                                        }}</span
                                                      >
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style="width: 100%"
                                                    class="text-center"
                                                    v-if="
                                                      item.containerMilestones
                                                        .length == 0
                                                    "
                                                  >
                                                    No container events
                                                  </div>
                                                </v-col>
                                              </v-row>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-tab-item>
                                <v-tab-item value="telemetry">
                                  <v-card
                                    flat
                                    style="
                                      background: var(
                                        --v-component-base
                                      ) !important;
                                    "
                                  >
                                    <!-- <v-card-title>
                                      <v-icon color="secondary" class="mr-2"
                                        >thermostat_auto</v-icon
                                      >
                                      Telemetry
                                    </v-card-title> -->
                                    <v-card-text>
                                      <div
                                        v-if="
                                          selectedContainer.telemetry &&
                                          selectedContainer.telemetry.length > 0
                                        "
                                        class="my-0 py-0"
                                      >
                                        <v-divider></v-divider>
                                        <v-subheader style="font-size: 16px"
                                          ><v-icon
                                            class="mr-2"
                                            color="secondary"
                                            >thermostat</v-icon
                                          >
                                          Monitoring
                                        </v-subheader>
                                        <v-divider></v-divider>
                                        <ContainerTelemetry
                                          :data="selectedContainer.telemetry"
                                        />
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                </v-tab-item>
                                <v-tab-item value="contents">
                                  <v-card flat style="border-radius: 0px">
                                    <v-card-text>
                                      <v-row justify="end">
                                        <v-col cols="12" sm="4">
                                          <el-input
                                            ref="searchBar"
                                            suffix-icon="el-icon-search"
                                            v-model="searchPallets"
                                            clearable
                                            placeholder="Search"
                                          >
                                          </el-input>
                                        </v-col>
                                      </v-row>
                                      <v-data-table
                                        :headers="palletHeaders"
                                        :items="item.containerPallets"
                                        :search="searchPallets"
                                        dense
                                        fixed-header
                                        disable-pagination
                                        hide-default-footer
                                      >
                                        <template
                                          v-slot:[`item.line`]="{ item }"
                                        >
                                          {{
                                            uniquePallets.indexOf(item.sscc) + 1
                                          }}
                                        </template>
                                      </v-data-table>
                                    </v-card-text>
                                  </v-card>
                                </v-tab-item>
                              </v-tabs-items>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>

                      <v-card-text :key="eventKey" v-if="!loadingContainer">
                        <v-card-text v-if="selectedContainer.events">
                          <!-- <v-divider></v-divider> -->
                          <v-subheader style="font-size: 16px"
                            ><v-icon class="mr-2" color="secondary"
                              >gps_not_fixed</v-icon
                            >
                            Container Tracking</v-subheader
                          >
                          <!-- <v-divider></v-divider> -->
                          <DCSAContainerTracking
                            class="mt-1 pt-1"
                            v-if="selectedContainer.eventType == 'DCSA'"
                            :events="selectedContainer.events"
                            :vertical="false"
                          />
                          <ContainerMilestone
                            class="mt-1 pt-1"
                            v-else
                            :events="selectedContainer.events"
                            :vertical="false"
                          />
                        </v-card-text>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else
                style="
                  height: 60vh;
                  padding-top: 0;
                  margin-top: 0;
                  background: transparent;
                "
                class="text-center"
              >
                <valhalla-loading :height="'60vh'" />
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card>

    <v-dialog
      v-model="showVesselTracking"
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="showVesselTracking">
        <v-card-title>
          <v-icon class="mr-2" color="secondary">gps_not_fixed</v-icon>
          {{ vesselTracking.vessel.name }}
          <span class="ml-2"> - {{ $route.query.voyage }}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="showVesselTracking = false">X</v-btn>
        </v-card-title>
        <v-card-text>
          <VesselTracking :voyage="vesselTracking" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import ContainerTelemetry from "../components/Telemetry/ContainerTelemetry.vue";
import dateFormat from "dateformat";
import * as moment from "moment";
import DCSAContainerTracking from "../components/ContainerTracking/DCSA.vue";
import ContainerMilestone from "../components/ContainerTracking/ContainerMilestone.vue";
import ProductBreakdownTable from "../components/SystemComponents/ProductBreakdownTable.vue";
import VesselTracking from "../components/ShipmentSummary/VesselTracking.vue";
export default {
  components: {
    ContainerTelemetry,
    DCSAContainerTracking,
    ContainerMilestone,
    ProductBreakdownTable,
    VesselTracking,
  },
  data: () => ({
    containerTab: "Summary",
    displayTelemetry: false,
    eventKey: 3000,
    item: {},
    loadingSensor: false,
    palletHeaders: [
      {
        text: "Line",
        value: "line",
        sortable: false,
        width: "5px",
        align: "center",
      },
      {
        text: "SSCC / Pallet ID",
        value: "sscc",
        sortable: false,
        align: "center",
      },
      {
        text: "Producer/ PUC(s)",
        value: "farm",
        sortable: false,
        align: "center",
      },
      {
        text: "Orchard",
        value: "orchard",
        sortable: false,
        align: "center",
      },
      {
        text: "Phyto Data",
        value: "phytoReference",
        sortable: false,
        align: "center",
      },
      {
        text: "PHC",
        value: "packHouse",
        sortable: false,
        align: "center",
      },
      {
        text: "Production Area",
        value: "productionArea",
        sortable: false,
        align: "center",
      },
      {
        text: "Commodity",
        value: "commodity",
        sortable: false,
        align: "center",
      },
      {
        text: "Variety",
        value: "variety",
        sortable: false,
        align: "center",
      },
      {
        text: "Flesh Colour",
        value: "fleshColour",
        sortable: false,
        align: "center",
      },
      {
        text: "Class/ Grade",
        value: "gradeClassCode",
        width: "20px",

        sortable: false,
        align: "center",
      },
      {
        text: "Count",
        value: "countSize",
        width: "20px",
        sortable: false,
        align: "center",
      },
      {
        text: "Pack Type",
        value: "packType",
        sortable: false,
        width: "10px",
        align: "right",
      },
      {
        text: "Quantity",
        value: "noCartons",
        sortable: false,
        width: "10px",
        align: "right",
      },
      {
        text: "Nett Weight (KG)",
        value: "nettWeight",
        sortable: false,
        align: "right",
      },
      {
        text: "Gross Weight (KG)",
        value: "grossWeight",
        sortable: false,
        align: "right",
      },

      {
        text: "PPECB Inspection Date",
        value: "inspectionDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Stuff Date",
        value: "stuffDate",
        width: "100px",
        sortable: false,
        align: "center",
      },
      {
        text: "Manifest No.",
        value: "inspectionManifestNo",
        sortable: false,
        align: "center",
      },
      {
        text: "Inspection Point",
        value: "inspectionPoint",
        sortable: false,
        align: "center",
      },
    ],
    productSummaryHeaders: [
      {
        text: "Product",
        value: "productName",
        align: "center",
      },
      {
        text: "Variety",
        value: "varietyName",
        align: "center",
      },
      {
        text: "Pack Code",
        value: "packCode",
        align: "center",
      },
      {
        text: "Grade/Class",
        value: "gradeClassCode",
        align: "center",
      },

      {
        text: "Count/Size",
        value: "countSize",
        align: "center",
      },
      {
        text: "No. Cartons",
        value: "noCartons",
        align: "center",
      },
      {
        text: "Percentage",
        value: "percentage",
        align: "center",
      },
      // {
      //   text: "Price per Carton",
      //   value: "cartonPrice",
      //   align: "center",
      // },
      // {
      //   text: "Price per KG",
      //   value: "kgPrice",
      //   align: "center",
      // },
    ],
    locationKey: 4000,
    loading: true,
    loadingBreakdown: false,
    loadingContainer: false,
    loadingContainerBreakdown: false,
    loadingProductSummary: false,
    loadingShipment: false,
    loadingVesselVoyageLegs: false,

    productKey: 0,
    productSummaryItems: [],
    searchPallets: null,
    selectedContainer: null,
    selectKey: 1000,
    selectedShipment: null,
    sensorData: [],
    showVesselTracking: false,
    shipments: [],
    summaryItems: [],
    shipmentSummaryItems: [],
    tab: "containers",
    telemetryKey: 2000,
    vesselTracking: null,
    vesselVoyageLegs: [],
  }),
  watch: {
    "$route.params.hash": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getShipmentOverviewDetail();
        }
      },
    },
    tab(val) {
      if (val && val == "shipments") {
        this.selectedContainer = null;
      }
      this.locationKey++;
    },
    "$route.query.vesselVoyageId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getVesselVoyageLegs(val);
        }
      },
    },
  },
  computed: {
    binsAndCartons() {
      let result = false;
      if (this.item && this.item.containerPallets) {
        let mapped = this.item.containerPallets.map((x) => x.packType);
        if (mapped.includes("BIN") && mapped.includes("CARTON")) {
          result = true;
        }
      }
      return result;
    },
    containers() {
      let result = [].concat.apply(
        [],
        this.shipments.map((x) => x.containers)
      );
      return result;
    },
    containerProducts() {
      let result = [];
      if (this.item && this.item.containerPallets) {
        let uniqueProducts = [
          ...new Set(
            this.item.containerPallets.map((x) => x.productId).filter(Boolean)
          ),
        ];
        for (let i = 0; i < uniqueProducts.length; i++) {
          let product = this.item.containerProducts.find(
            (x) => x.productId == uniqueProducts[i]
          );
          if(!product) {
            product = this.item.containerPallets.find(
              (x) => x.productId == uniqueProducts[i]
            );
          }
          let obj = {
            productId: uniqueProducts[i],
            product: product.product,
            productTree: product.productTree,
            noPallets: [
              ...new Set(
                this.item.containerPallets
                  .filter((x) => x.productId == uniqueProducts[i])
                  .map((x) => x.sscc)
              ),
            ].length,
            noCartons: this.item.containerPallets
              .filter((x) => x.productId == uniqueProducts[i])
              .map((x) => parseInt(x.noCartons))
              .reduce((a, b) => a + b, 0),
          };
          result.push(obj);
        }
        result.sort((a, b) =>
          a.product.name > b.product.name
            ? 1
            : b.product.name > a.product.name
            ? -1
            : 0
        );
      }
      console.log(result);
      return result;
    },
    progress() {
      let result = 0;
      if (new Date(this.$route.query.etd) < new Date()) {
        let difference = this.calculateDays(
          new Date(this.$route.query.etd),
          new Date()
        );
        let shipmentTotal = this.calculateDays(
          new Date(this.$route.query.etd),
          new Date(this.$route.query.eta)
        );
        difference = difference > shipmentTotal ? shipmentTotal : difference;
        result = difference > 0 ? difference : 0;
      }
      return result;
    },
    uniquePallets() {
      let result = [];
      if (this.item && this.item.containerPallets) {
        result = [
          ...new Set(this.item.containerPallets.map((item) => item.sscc)),
        ];
      }
      return result;
    },
  },
  methods: {
    calculateProductCartons(productId) {
      let pallets = this.item.containerPallets.filter(
        (x) => x.productId == productId && x.packType == "CARTON"
      );
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductBins(productId) {
      let bins = this.item.containerPallets.filter(
        (x) => x.productId == productId && x.packType == "BIN"
      );
      return bins.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateUniquePallets(pallets) {
      return [...new Set(pallets.map((item) => item.sscc))].length;
    },
    calculateCartons(pallets) {
      let result = pallets.map((item) => parseInt(item.noCartons));
      return result && result.length > 0 ? result.reduce((a, b) => a + b) : 0;
    },
    calculateActualGrossWeight(pallets) {
      return pallets.map((item) => item.grossWeight).reduce((a, b) => a + b);
    },
    calculateActualNettWeight(pallets) {
      return pallets.map((item) => item.nettWeight).reduce((a, b) => a + b);
    },
    containerGrossWeight(container) {
      return container.containerPallets
        .map((item) => Math.round(parseFloat(item.grossWeight) * 100) / 100)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    },
    containerNettWeight(container) {
      return container.containerPallets
        .map((item) => Math.round(parseFloat(item.nettWeight) * 100) / 100)
        .reduce((a, b) => a + b, 0)
        .toFixed(2);
    },
    calculateDays(start, end) {
      start = moment(start);
      end = moment(end);
      let difference = end.diff(start, "days");
      let count = difference;
      return count;
    },
    calculatePercentage(start, end) {
      let result = parseInt(start) / parseInt(end);
      return Math.round(result * 100);
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
      }
      return result;
    },
    async getContainerTelemetry(item) {
      item.telemetry = [];
      this.displayTelemetry = false;
      let result = await this.$API.getContainerTelemetryData(item.id);
      item.telemetry = result;
      this.displayTelemetry = item.telemetry.length > 0;
      this.telemetryKey++;
    },
    getStatusColor(status) {
      switch (status) {
        case "Awaiting Departure":
          return "orange";
        case "In Transit":
          return "blue";
        case "Arrived":
          return "green";
        case "Cancelled":
          return "red";
        default:
          return "darkgrey";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "Awaiting Departure":
          return "location_on";
        case "In Transit":
          return "mode_of_travel";
        case "Arrived":
          return "where_to_vote";
        case "Cancelled":
          return "close";
        default:
          return "info";
      }
    },
    async getShipmentProductBreakdown(id){
            this.loadingShipment= true
            this.shipmentSummaryItems = await this.$API.getShipmentProductSummary(id)
            this.loadingShipment = false
        },
    async getShipmentOverviewDetail() {
      this.loading = true;
      this.shipments = await this.$API.getShipmentOverviewDetail(
        this.$route.query
      );
      if (this.containers && this.containers.length > 0) {
        this.selectContainer(this.containers[0]);
      }
      this.productSummaryItems =
        await this.$API.getShipmentOverviewProductSummary(this.$route.query);
      this.productSummaryItems = this.productSummaryItems.map((x) => ({
        ...x,
        cartonPrice: "?",
        kgPrice: "?",
      }));
      this.vesselTracking = await this.$API.getVesselVoyageTracking(
        this.$route.query.vesselVoyageId
      );
      this.productKey++;
      this.loading = false;
    },
    async getContainerProductSummary(item) {
      this.loadingContainerBreakdown = true;
      let result = await this.$API.getContainerSummary(
        item.shipmentId,
        item.id
      );
      item.productSummary = result;
      item.productSummary = item.productSummary.map((x) => ({
        ...x,
        cartonPrice: "?",
        kgPrice: "?",
      }));
      this.loadingContainerBreakdown = false;
    },
    async getVesselVoyageLegs(id) {
      this.loadingVesselVoyageLegs = true;
      this.vesselVoyageLegs = await this.$API.getVoyageLegs(id);
      this.loadingVesselVoyageLegs = false;
    },
    getPackType() {
      let type = this.item.containerPallets.find((x) => x.packType);
      return type ? type.packType : "CARTONS";
    },
    getDocumentStatusColor(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "orange";
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "cancelled":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "delivered":
          return "green";
        case "in progress":
          return "indigo";
        case "created":
          return "blue";
        case "submitted":
          return "blue";
        case "resubmitted":
          return "cyan";
        case "draft":
          return "orange";
        case "file generated":
          return "orange";
        case "pending":
          return "orange";
        case status.includes("pending"):
          return "orange";
        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "rejected":
          return "red";
        case "query":
          return "purple";
        case "in transnit":
          return "orange";
        case "failed":
          return "red";
        case "new":
          return "amber";
        case "error":
          return "red";
        case "not created":
          return "red";
        case "in transit":
          return "blue";
        case "validation failed":
          return "red";
        case "inspection failed":
          return "red";
        case "inspection approved":
          return "success";
        case status.toLowerCase().includes("pending"):
          return "orange";
        case status.toLowerCase().includes("approved"):
          return "green";
        case status.toLowerCase().includes("failed"):
          return "red";
        case status.toLowerCase().includes("revoked"):
          return "red";
        default:
          return "grey";
      }
    },
    getDocumentStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "action required":
          return "warning";
        case "not required":
          return "close";
        case "cancelled":
          return "cancel";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "draft":
          return "edit_note";
        case "created":
          return "mark_email_read";
        case "submitted":
          return "mark_email_read";
        case "resubmitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "delivered":
          return "task_alt";
        case "file generated":
          return "task";
        case "new":
          return "star";
        case "error":
          return "cancel";
        case "failed":
          return "cancel";
        case "rejected":
          return "cancel";
        case "not created":
          return "error";
        case "approved":
          return "fact_check";
        case "validation failed":
          return "error";
        case "inspection failed":
          return "error";
        case "inspection approved":
          return "fact_check";
        case "in transit":
          return "local_shipping";
        case status.toLowerCase().includes("pending"):
          return "pending";
        case status.toLowerCase().includes("approved"):
          return "fact_check";
        case status.toLowerCase().includes("failed"):
          return "error";
        case status.toLowerCase().includes("revoked"):
          return "error";
        default:
          return "info";
      }
    },
    async selectContainer(item) {
      this.selectedContainer = item;
      this.selectedShipment = null;
      this.loadingContainer = true;
      this.containerTab = "summary";
      //   this.loading = true;
      this.item = await this.$API.getContainer(item.id);
      //   this.loading = false;
      this.eventType = null;
      this.sensorData = [];
      if (!item.productSummary) {
        item.productSummary = [];
        await this.getContainerProductSummary(item);
        this.productKey++;
      }
      if (!item.telemetry) {
        item.telemetry = [];
        await this.getContainerTelemetry(item);
      }
      this.loadingBreakdown = true;
      this.summaryItems = await this.$API.getContainerSummary(
        this.item.shipmentId,
        this.item.id
      );
      this.loadingBreakdown = false;
      this.loadingContainer = false;
    },
    async selectShipment(item) {
      this.selectedShipment = item;
      this.selectedContainer = null;
      this.getShipmentProductBreakdown(item.id);
    },
    viewShipment(item) {
      this.$router.push({
        path: "/shipment/" + item.shipmentReference,
      });
    },
  },
};
</script>
<style>
.mapLocal ::v-deep .gm-style-cc {
  display: none;
}
</style>