var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('gmap-map',{key:_vm.key,staticClass:"mapLocal",staticStyle:{"width":"100%","height":"600px"},attrs:{"center":{ lat: 0.00, lng: 0.00 },"zoom":2.4,"options":_vm.mapStyle}},[_c('gmap-polyline',{attrs:{"path":_vm.actualRoute,"options":{
                    strokeColor: 'green',
                    icons: [
                        {
                            icon: {
                                path: 'M 0,0 1,3 -1,3 0,0 z',
                                strokeOpacity: 1,
                                scale: 2.5,
                            },
                            offset: '0',
                            repeat: '50px',
                        },
                    ],
                }},on:{"update:path":function($event){_vm.actualRoute=$event}}}),_c('gmap-polyline',{attrs:{"path":_vm.estimatedRoute,"options":{
                    strokeColor: '#607e87',
                    strokeOpacity: 0,
                    icons: [{
                        icon: {
                            path: 'M 0,-1 0,1',
                            strokeOpacity: 1,
                            scale: 3
                        },
                        offset: '0',
                        repeat: '20px'
                    }],
                }},on:{"update:path":function($event){_vm.estimatedRoute=$event}}}),(_vm.activeLocation)?_c('gmap-custom-marker',{attrs:{"alignment":"center","marker":{ lat: _vm.activeLocation.lat, lng: _vm.activeLocation.lng }}},[_c('v-icon',{staticClass:"pulse-beacon",attrs:{"color":"green"}},[_vm._v("adjust")])],1):_vm._e(),(_vm.originLocation)?_c('gmap-custom-marker',{attrs:{"offsetY":-18,"alignment":"center","marker":{
                    lng: _vm.originLocation.lng,
                    lat: _vm.originLocation.lat,
                }}},[_c('div',{staticStyle:{"border-radius":"50%","background-color":"var(--v-primary-base) !important","border":"3px solid var(--v-primary-base)"}},[_c('v-avatar',{attrs:{"size":"40"}},[(_vm.voyage.startLocode)?_c('v-img',{staticClass:"pa-0",attrs:{"contain":"","src":`https://cdn.loglive.io/flags/4x3/${_vm.voyage.startLocode.substring(0, 2).toLowerCase()}.svg`}}):_vm._e()],1),_c('div',{staticClass:"map-marker"})],1)]):_vm._e(),(_vm.destinationLocation)?_c('gmap-custom-marker',{attrs:{"offsetY":-18,"alignment":"center","marker":{
                    lng: _vm.destinationLocation.lng,
                    lat: _vm.destinationLocation.lat,
                }}},[_c('div',{staticStyle:{"border-radius":"50%","background-color":"var(--v-primary-base) !important","border":"3px solid var(--v-primary-base)"}},[_c('v-avatar',{attrs:{"size":"40"}},[(_vm.voyage.endLocode)?_c('v-img',{staticClass:"pa-0",attrs:{"contain":"","src":`https://cdn.loglive.io/flags/4x3/${_vm.voyage.endLocode.substring(0, 2).toLowerCase()}.svg`}}):_vm._e()],1),_c('div',{staticClass:"map-marker"})],1)]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }