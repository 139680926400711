<template>
    <div>
        <v-card flat>
            <v-card-text class="ma-0 pa-0">
                <gmap-map :center="{ lat: 0.00, lng: 0.00 }" :zoom="2.4" :key="key" style="width: 100%; height: 600px;"
                    v-bind:options="mapStyle" class="mapLocal">
                    <gmap-polyline v-bind:path.sync="actualRoute" v-bind:options="{
                        strokeColor: 'green',
                        icons: [
                            {
                                icon: {
                                    path: 'M 0,0 1,3 -1,3 0,0 z',
                                    strokeOpacity: 1,
                                    scale: 2.5,
                                },
                                offset: '0',
                                repeat: '50px',
                            },
                        ],
                    }"></gmap-polyline>

                    <gmap-polyline v-bind:path.sync="estimatedRoute" v-bind:options="{
                        strokeColor: '#607e87',
                        strokeOpacity: 0,
                        icons: [{
                            icon: {
                                path: 'M 0,-1 0,1',
                                strokeOpacity: 1,
                                scale: 3
                            },
                            offset: '0',
                            repeat: '20px'
                        }],
                    }"></gmap-polyline>
                    <gmap-custom-marker v-if="activeLocation" alignment="center"
                        :marker="{ lat: activeLocation.lat, lng: activeLocation.lng }">
                        <v-icon class="pulse-beacon" color="green">adjust</v-icon>
                    </gmap-custom-marker>

                    <gmap-custom-marker :offsetY="-18" v-if="originLocation" alignment="center" :marker="{
                        lng: originLocation.lng,
                        lat: originLocation.lat,
                    }">
                        <div
                            style="border-radius: 50%; background-color: var(--v-primary-base) !important;border:  3px solid var(--v-primary-base)">
                            <v-avatar size="40">
                                <v-img contain v-if="voyage.startLocode" class="pa-0"
                                    :src="`https://cdn.loglive.io/flags/4x3/${voyage.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                            </v-avatar>
                            <div class="map-marker"></div>
                        </div>

                    </gmap-custom-marker>

                    <gmap-custom-marker :offsetY="-18" v-if="destinationLocation" alignment="center" :marker="{
                        lng: destinationLocation.lng,
                        lat: destinationLocation.lat,
                    }">
                        <div
                            style="border-radius: 50%; background-color: var(--v-primary-base) !important;border:  3px solid var(--v-primary-base)">
                            <v-avatar size="40">
                                <v-img contain v-if="voyage.endLocode" class="pa-0"
                                    :src="`https://cdn.loglive.io/flags/4x3/${voyage.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                            </v-avatar>
                            <div class="map-marker"></div>
                        </div>

                    </gmap-custom-marker>

                    <!-- 
                                           
                                            <gmap-custom-marker v-for="device in locationData" :key="device.id"
                                                alignment="center" :marker="device.startLocation">
                                                <v-icon :color="device.color">location_on</v-icon>
                                            </gmap-custom-marker> -->
                </gmap-map>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
    props: ['voyage'],
    components: {
        GmapCustomMarker
    },
    watch: {
        voyage() {
            this.key++
        }
    },
    data: () => ({
        key: 1000,
        mapStyle: {
            scrollwheel: true,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: [
                { elementType: "geometry", stylers: [{ color: "#283a54" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#283a54" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                    featureType: "administrative.locality",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }],
                },
                {
                    featureType: "poi",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }],
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#263c3f" }],
                },
                {
                    featureType: "poi.park",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#6b9a76" }],
                },
                {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [{ color: "#38414e" }],
                },
                {
                    featureType: "road",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#212a37" }],
                },
                {
                    featureType: "road",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#9ca5b3" }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [{ color: "#746855" }],
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#1f2835" }],
                },
                {
                    featureType: "road.highway",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#f3d19c" }],
                },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#2f3948" }],
                },
                {
                    featureType: "transit.station",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }],
                },
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#212121" }],
                },
                {
                    featureType: "water",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#515c6d" }],
                },
                {
                    featureType: "water",
                    elementType: "labels.text.stroke",
                    stylers: [{ color: "#17263c" }],
                },
            ],
        },
    }),
    computed: {
        activeLocation() {
            let result = null
            if (this.voyage && this.voyage.routeHistory) {
                result = this.voyage.routeHistory.at(-1)
            }
            return result
        },
        originLocation() {
            let result = null
            if (this.voyage && this.voyage.routeHistory) {
                result = this.voyage.routeHistory[0]
            }
            return result
        },
        destinationLocation() {
            let result = null
            if (this.voyage && this.voyage.routeEstimate && this.voyage.routeEstimate.Path && this.voyage.routeEstimate.Path.Points.length > 0) {
                result = this.voyage.routeEstimate.Path.Points.map(x => ({
                    lat: x[1],
                    lng: x[0]
                })).at(-1)
            }
            return result
        },
        actualRoute() {
            let result = []
            if (this.voyage && this.voyage.routeHistory) {
                result = this.voyage.routeHistory.map(x => ({
                    lat: x.lat,
                    lng: x.lng
                }))
            }
            return result
        },
        estimatedRoute() {
            let result = []
            if (this.voyage && this.voyage.routeEstimate && this.voyage.routeEstimate.Path && this.voyage.routeEstimate.Path.Points.length > 0) {
                result = this.voyage.routeEstimate.Path.Points.map(x => ({
                    lat: x[1],
                    lng: x[0]
                }))
            }
            return result
        }
    }
}
</script>

<style scoped>
.pulse-beacon {
    animation: pulse-beacon 2s infinite;
    z-index: 10;
}

@keyframes pulse-beacon {
    0% {
        border-radius: 50%;
        box-shadow: 0 0 0 0px rgb(135, 135, 135);
    }

    100% {
        border-radius: 50%;
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.map-marker {
    /* position: relative; */
}

.map-marker::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    /* top: -5px; */
    bottom: -20px;
    left: 10px;
    border: 10px solid transparent;
    border-top: 17px solid var(--v-primary-base);
}
</style>